export enum BANNER_LOCATION {
  HOME = 'HOME',
  SIGN_IN = 'SIGNIN'
}

export enum ADMIN_CONTENT_TAB {
  POST = 'post',
  LINK = 'link',
  BANNER = 'banner'
}
